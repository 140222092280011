import * as React from "react";
import { Link } from "react-router-dom";

import { classnames } from "utils/classnames";
import { Icon } from "./Icons";

interface IButtonProps {
  as?: "a" | "button" | "div" | "Link";
  type?: "submit" | "button" | "reset";
  children: React.ReactNode;
  icon?: Icon;
  accented?: boolean;
  danger?: boolean;
  disabled?: boolean;
  size?: "normal" | "small" | "for-icon";
  href?: string;
  download?: string;
  className?: string;
  onClick?: (e: React.MouseEvent<any>) => void;
}

const sizeClasses = {
  normal: "min-h-14 px-8 py-1",
  small: "min-h-10 px-6 py-1 text-sm",
  "for-icon": "min-w-10 min-h-10 p-1",
};

const Button = ({
  as: Component = "button",
  type,
  children,
  icon: IconComponent,
  accented,
  danger,
  disabled,
  size = "normal",
  href,
  className,
  onClick,
  ...rest
}: IButtonProps) => {
  const classes = classnames(
    "flex flex-row items-center justify-center",
    "font-bold uppercase bg-gradient-to-b hover:bg-gradient-to-b",
    sizeClasses[size],
    disabled
      ? "from-zinc-400 to-zinc-500 cursor-not-allowed"
      : accented
      ? "from-bright-navy-blue to-persian-blue hover:from-dodger-blue hover:to-persian-blue"
      : danger
      ? "from-red-600 to-red-800 hover:from-red-500 hover:to-red-800"
      : "from-white/15 to-black hover:from-white/25 hover:to-black border-2 border-white",
    className,
  );

  const iconEl = IconComponent && <IconComponent className="h-[1.1em] mr-[0.6em] fill-white" />;

  if (Component === "Link")
    return (
      <Link to={href || "#"} className={classes} onClick={onClick} {...rest}>
        {iconEl}
        {children}
      </Link>
    );

  return (
    <Component href={href} type={type} className={classes} onClick={onClick} {...rest}>
      {iconEl}
      {children}
    </Component>
  );
};

export default Button;
